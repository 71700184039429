<template>
  <div class="">
  
    <div class="">
      <div class="">
        <div class="">
         
          <div  v-if="isLoading" class="loader d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
              <b-spinner small style="color: #4d1b7e"></b-spinner>
              <span class="ml-2">Loading Flows</span>
            </div>
          </div>
          <!-- -->
          <b-skeleton-table
            v-if="isLoadingFirstTime"
            :rows="5"
            :columns="4"
            :table-props="{ bordered: true }"></b-skeleton-table>
          <b-table
           row-class="custom-row-class"
            v-else-if="items.length > 0"
            :responsive="true"
            hover
            head-class="text-center"
            class="bg-table-heading bg-table-heading"
            
            :fields="fields"
            :items="items"
            show-empty>
        
            
            <template #cell(status)="data">
              <div class="d-flex ">
                <div class="">
                  <span class=" custom-control custom-switch mt-2" >
        <input
          type="checkbox"
          class="custom-control-input "
          :id="`flowToggle-${data.item.id}`"
          :checked="isLive(data.item.status)"
          @change="toggleStatus(data.item)"
        />
        <!-- v-model="data.item.status" -->
        <label class="custom-control-label" :for="`flowToggle-${data.item.id}`"></label>
      </span>
                </div>
              </div>
             
            </template>
            <template #cell(created_at)="data">
              {{ formattedDate(data.item.created_at) }}
            </template>
             <template #cell(actions)="data">
                <b-dropdown  dropright class="" id="collection-detail">
                  <template slot="button-content">
                    <div class="p-2">
                      <i class="text-dark fa-solid fa-ellipsis-vertical"></i>
                    </div>
                  </template>
                  <b-dropdown-item class="topbarLink d-flex align-items-center" @click="$router.push('/manage/emails/flows/'+data.item.uuid)">
                    Edit</b-dropdown-item
                  >
                  <b-dropdown-item  class="topbarLink d-flex align-items-center" @click="()=>{$refs.segmentsBulkDelete.open(); deleteSegmentId=data.item.uuid}">
                    Delete</b-dropdown-item
                  >
                
                </b-dropdown>
              </template>
          </b-table>
          <template v-else>

<div class="d-flex justify-content-center align-items-center"  >

<EmptyTable >
<template #title>
No flow found
</template>
<template #description>
Time to fill your shelves or refine your search. Remember, your results will appear right here.
</template>
</EmptyTable>
</div>

</template>
      
            <QuizellPagination  :total-rows="totalRows" :currentPage="currentPage"
                  :per-page="perPage"   v-if="items.length" @update="updatePagination" @UpdatePerPage="updatePerPage"></QuizellPagination>
        </div>
       
      </div>
      
    </div>
    <DeleteModal ref="segmentsBulkDelete" id="segmentsBulkDelete" title="Delete Flow" item="flow"  @click="Deletesegment()" :isLoading="deleteSegmentLoading"></DeleteModal>
    <b-modal ref="segmentDelete" id="segmentsBulkDelete" title="Delete Flows">
                <p class="my-4">
                  Are you sure you want to delete the selected flows? This can’t
                  be undone.
                </p>
                <template #modal-footer>
                  <div class="w-100 d-flex justify-content-end">
                    <b-button
                      :class="deleteSegmentLoading ? 'disabled' : ''"
                      variant="danger"
                      @click="Deletesegment()">
                      <span v-if="!deleteSegmentLoading"> Confirm </span>
                      <div
                        v-else
                        class="spinner-border spinner-border-sm"
                        role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </b-button>
                  </div>
                </template>
              </b-modal>
   <FlowNameTitleModal ref="flowEmailTitleModal"></FlowNameTitleModal>
   <FooterContent >
<div class="d-flex align-items-center justify-content-end">
  <Button @click="$refs.flowEmailTitleModal.openModal()"> + Create Flow </Button>
</div>
</FooterContent>
  
  </div>
</template>
<script>
import DeleteModal from '../../modal/DeleteModal.vue';
import {mapActions} from 'vuex'
import FlowNameTitleModal from './FlowNameTitleModal.vue'
import axios from "axios";
import Button from '../../Layout/Button.vue';
import QuizellPagination from '../../Layout/QuizellPagination.vue';
import FooterContent from '../../Layout/FooterContent.vue';
import EmptyTable from '../../Layout/EmptyTable.vue';
// import UpdateModal from "./UpdateModal.vue";
// import CreateModal from "./CreateModal.vue";
// import Button from "../Layout/Button.vue";
export default {
  components: {
    EmptyTable,
    DeleteModal,
    FooterContent,
    Button,
    QuizellPagination,
    FlowNameTitleModal
// UpdateModal,
//     CreateModal
    // Button
  },
  
  computed: {
   
   
  
  },
  data() {
    return {
     currentPage: 1,
      perPage: 10,
      totalRows: 20,
      deleteSegmentLoading: false,
      deleteSegmentId:'',
      isLoading: false,
      
      isLoadingFirstTime: true,
      fields: [
       
        {
          key: "title",
          label: "Flow Name",
          tdClass: "data-Table cursor-pointer align-middle cursor-pointer",
          thClass: "bg-table-heading",
        },
        {
          key: "steps_count",
          label: "Steps Number",
          tdClass: "data-Table cursor-pointer align-middle cursor-pointer",
          thClass: "bg-table-heading",
        },
        {
          key: "emails_count",
          label: "Email Sent",
          tdClass: "data-Table cursor-pointer align-middle cursor-pointer",
          thClass: "bg-table-heading",
        },
        {
          key: "status",
          label: "Status",
          tdClass: "data-Table cursor-pointer align-middle cursor-pointer",
          thClass: "bg-table-heading",
        },
        {
          key: "created_at",
          label: "Created",
          tdClass: "data-Table cursor-pointer align-middle cursor-pointer",
          thClass: "bg-table-heading",
        },
          {
          key: "actions",
          label: "",
           tdClass: "data-Table cursor-pointer align-middle cursor-pointer",
          thClass: "bg-table-heading",
        },
      ],
      items: [
        {
         
        }
      ],
    };
  },
  methods: {
    applyFilters() {
      const queryParams = {};
      queryParams.perPage=this.perPage;
      queryParams.page=this.currentPage;
      this.$router.replace({ query: queryParams });
      this.getFlowsList()
    },
    updatePerPage(e){
      this.currentPage=1;
      this.perPage=e;
      this.applyFilters();
    },
    updatePagination(e){
      this.currentPage=e;
      this.applyFilters();
    },
 
    isLive(status) {
      return status === 'live';
    },
  async  toggleStatus(item) {
      // item.status = this.isLive(item.status) ? 'draft' : 'live';


      try {
        this.$toasted.show("Updating Status, This may take a while!", {
                theme: "toasted-primary",
                position: "bottom-center",
                duration: 2000,
              });

        let data = {
          status : this.isLive(item.status) ? 'draft' : 'live'
        }

        const response = await this.fetchData({
        axiosMethod:'put',
        resource:'flow',
        method:'update',
        id:item.uuid,
        params: data,
      });
      if(response)
      this.$toasted.show("Status Updated Successfully", {
                theme: "toasted-primary",
                position: "bottom-center",
                duration: 2000,
              });

              this.getFlowsList()


        
      } catch (error) {
        if(error){
          this.$toasted.show("Error occured", {
                theme: "toasted-primary",
                position: "bottom-center",
                duration: 2000,
              });
            }
            throw error;
      }
    },
 ...mapActions(['fetchData']),
    formattedDate(timestamp) {
      const date = new Date(timestamp);

      const day = String(date.getUTCDate()).padStart(2, "0");
      const month = String(date.getUTCMonth() + 1).padStart(2, "0");
      const year = String(date.getUTCFullYear());

      return `${day}-${month}-${year}`;
    },


    async Deletesegment() {
     
        this.deleteSegmentLoading = true;
        await axios
          .delete(`/api/v1/flows/`+this.deleteSegmentId)
          .then(() => {
            this.$toasted.show("Flows are successfully deleted", {
              theme: "toasted-primary",
              position: "bottom-center",
              duration: 2000,
            });
           this.deleteSegmentId='';
           this.$refs.segmentsBulkDelete.close();
            this.getFlowsList();
          })
          .catch((error) => {
            if (error) {
              this.$toasted.show("Error occured", {
                theme: "toasted-primary",
                position: "bottom-center",
                duration: 2000,
              });
            }
            throw error;
          });
        this.deleteSegmentLoading = false;
      }
    ,
    async getFlowsList() {
      this.isLoading = true;
     const data = await this.fetchData({
        axiosMethod:'get',
        resource:'flow',
        method:'list',
         params:{params:{page:this.currentPage,per_page:this.perPage}}
      });
      if(data)
       this.items =data.data.data;
      this.totalRows = data.data.total;
      this.isLoadingFirstTime = false;
       this.isLoading = false;
    },
  },
  created(){
    const query = this.$route.query;
    this.currentPage= parseInt(query.page) || 1;
    this.perPage= parseInt(query.perPage) || 10;
  },
  async mounted() {
    await this.getFlowsList();

  },
};
</script>

<style scoped>
.search-text:focus-visible {
  outline: none;
}
.search-text:active + .fa-search {
  color: #4d1b7e;
}
.tag {
  background: #bfbfc0;
  border-radius: 20px;
  border: 1px dashed;
  font-size: 14px;
}
.icon {
  color: #949495;
  transition: 0.5s ease all;
}
.icon:hover {
  background: rgb(77 27 126 / 20%);
  color: #4d1b7e;
}
.search {
  border-radius: 6px;
  border: 0.8px solid #b3afb6;
}
.search:focus-within {
  color: #495057;
  background-color: #fff;
  /* border-color: #4d1b7e; */
  outline: none;
  box-shadow: 0 0 0 0.1rem rgba(77, 27, 126, 0.25);
}
.cursor-pointer {
  cursor: pointer;
}
.checkbox >>> .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #ffa201;
  background-color: #ffa201 !important;
}
.checkbox >>> .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem #ffa201;
}
.floatingDiv {
  position: fixed;
  top: auto;
  bottom: 20px;
  width: 90%;
}

.floatingDiv .InnerDiv {
  z-index: 999 !important;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0rem 0.125rem 0.25rem #1f21241a, 0rem 0.0625rem 0.375rem #1f21240d,
    0rem 0.25rem 1.125rem -0.125rem #1f212414,
    0rem 0.75rem 1.125rem -0.125rem #1f212426;
  padding: 10px;
}
.deleteProduct {
  background: rgba(192, 21, 21, 0.2);
  border-radius: 6px;
  color: #c01515;
}
.download-btn {
  border-radius: 4px;
  background: rgba(77, 27, 126, 0.2);
}
.download-title {
  color: #1f1f28;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
}
.loader {
  min-height: 40px;
}
.bg-table-heading bg-table-heading >>> thead {
  border-radius: 6px;
  color: black;
  background: rgba(179, 175, 182, 0.07);
  border: none;
}
.bg-table-heading bg-table-heading >>> thead tr {
  border-radius: 10px;
  cursor:pointer;
}
.bg-table-heading bg-table-heading >>> thead th {
  border: none;
}
.customPagination >>> li > button {
  color: #4d1b7e;
}

.customPagination >>> li.active > button {
  color: white;
  background-color: #4d1b7e !important;
  border-color: #4d1b7e;
}
.customPagination >>> li > button:hover {
  color: white;
  background-color: #6b4690 !important;
  border-color: #4d1b7e;
}
.dropdown >>> .btn-danger {
  color: #4d1b7e;
  background: rgba(77, 27, 126, 0.12);
  font-weight: 600;
  border: none;
}
.dropdown >>> .btn-danger:active {
  color: #3f1768;
  background: rgba(77, 27, 126, 0.12);
  font-weight: 600;
  border: none;
}
.date >>> #Starting,
.date >>> #ending {
  color: #3f1768;
}
.collapse-title {
  color: #0D7DE4;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.custom-row-class:hover {
  cursor: pointer; /* Change this to the desired cursor style */
}

.collapsed > .collapse-title > .when-opened,
:not(.collapsed) > .collapse-title > .when-closed {
  display: none;
}
.list-col{
  color: #18191C;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.56px;
text-transform: capitalize;

}
.definitions{
  color: #18191C;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
#collection-detail >>> button {
  background: transparent;
  border: none;
  margin: 0;
  padding: 0;
}
#collection-detail >>> button::after {
  display: none;
}
#collection-detail >>> ul {
  background: #ffffff;
  box-shadow: 0px 10px 20px -3px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
}
#collection-detail >>> li.topbarLink {
  color: #344054;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 5px;
}
#collection-detail >>> li a:active {
  color: #fff;
  text-decoration: none;
  background-color: #4d1b7e;
}
.custom-control-input:checked ~ .custom-control-label::before {
  background-color: #e0dee2 !important;
  border-color: #ced3d7 !important;
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #ffa201 !important;
  border-color: #ced3d7 !important;
}
</style>
