<template>
    <div class="" v-if="!isLoadingFirstTime">
       
        <div class="row">
            <div class="col-md-3 col-6" v-for="(goal,index) in flowTemplates" :key="index">
            <div class="card p-2" s @click="storeFlow(goal.flow_id,index)" style="border: 1px solid rgba(31, 31, 40, 0.08); border-radius:12px;" :style="{'pointer-events':isSelected==index?'none':'all'}">
            <div class="card-body p-0" style="height: 316px">
            <div class="icon" v-if="!(isSelected==index)">
                <div class=""  v-html="goal.svg_icon">
                    
                </div>
            </div>
            <div class="icon" v-else >

                <b-spinner style="color: var(--primary-color);"></b-spinner>
            </div>
    
            <div class="h-50 d-flex flex-column justify-content-between">
               <div class="">
                <div class="goal-heading my-2">{{ goal.title }}</div>
                <div class="goal-description">{{ goal.description }}</div>
               </div>
               <div class="d-flex justify-content-between">
                <div class="heading-button">
                    {{goal.steps_count}} Steps
                </div>
                <div class="">
                    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.17726 14.0775L6.35577 15.256L13.4268 8.18497L13.4268 14.6668H15.1003V5.33297H5.76651V7.00646L12.2483 7.00646L5.17726 14.0775Z" fill="#B3AFB6"/>
</svg>

                </div>
               </div>
            </div>
            </div>
            </div>
            </div>
        </div>
        <div class="card mt-3" style="    border: none;
    background: rgba(179, 175, 182, 0.2);">
            <div class="card-body" @click="storeFlow('',-1)">
                <div class="d-flex justify-content-start align-items-center" style="gap:10px">
                    <div class="svg">
                        <svg width="64" v-if="!(isSelected==-1)" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.15" d="M50.6666 56V12H39.9999V16H23.9999V12H13.3333V56H50.6666Z" fill="#4D1B7E"/>
<path d="M21.3333 45.3333H42.6666M21.3333 34.6667H37.3333M23.9999 12H13.3333V56H50.6666V12H39.9999M23.9999 12V16H39.9999V12M23.9999 12V8H39.9999V12" stroke="#4D1B7E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
<b-spinner v-else style="color: var(--primary-color);"></b-spinner>


                    </div>
                    <div class="">
                        <div class="quizell-card-heading">
                            Start with the essentials
                        </div>
                        <div class="quizell-card-description">
                            Start with this flows to increase revenue and engagement with little effort.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row" v-else>
            <div class="col-md-3 col-6" v-for="index in 4" :key="index">
            <div class="card p-2"  style="border: 1px solid rgba(31, 31, 40, 0.08); border-radius:12px">
            <div class="card-body p-0" >
            <div class="icon" >
                <div
                    
                    class="integrationImgae bg-preload"
                    style="height: 100px; width: 100%; border-radius: 14px"
                  >
                    <div class="into-preload"></div>
                  </div>
                    
                </div>
            </div>
            
    
            <div class="h-50 d-flex flex-column justify-content-between">
               <div class="">
                <div class="goal-heading my-2"><div
                    
                    class="integrationImgae bg-preload"
                    style="height: 50px; width: 100%; border-radius: 14px"
                  >
                    <div class="into-preload"></div>
                  </div></div>
                <div class="goal-description"><div
                    
                    class="integrationImgae bg-preload"
                    style="height: 70px; width: 100%; border-radius: 14px"
                  >
                    <div class="into-preload"></div>
                  </div></div>
               </div>
               
            </div>
            </div>
            </div>
            </div>
        
</template>
<script>
import axios from 'axios';
export default{
    props:{
store:Function
    },
    data(){
        return {
            isLoadingFirstTime:false,
            isSelected:-2,
            goals:[{
                heading:'Prevent lost sales',
                description:'Turn browses and abandoned carts into purchases with these high-converting flows',
                icon:`<svg width="53" height="50" viewBox="0 0 53 50" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1.33325 1H7.99992L13.3333 38.3333H41.3333M41.3333 38.3333C38.3877 38.3333 35.9999 40.7211 35.9999 43.6667C35.9999 46.6122 38.3877 49 41.3333 49C44.2788 49 46.6666 46.6122 46.6666 43.6667C46.6666 40.7211 44.2788 38.3333 41.3333 38.3333ZM12.1904 30.3333H43.9999L51.9999 6.33333H8.7618M25.3333 43.6667C25.3333 46.6122 22.9454 49 19.9999 49C17.0544 49 14.6666 46.6122 14.6666 43.6667C14.6666 40.7211 17.0544 38.3333 19.9999 38.3333C22.9454 38.3333 25.3333 40.7211 25.3333 43.6667Z" stroke="#4D1B7E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`,
steps:1
            },
            {
                heading:'Nurture subscribers',
                description:'Turn subscribers into customers by sending them messages at key points',
                icon:`<svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.15" d="M56 48.0001V16.0001C56 15.2637 55.7015 14.597 55.219 14.1144L54.6667 14.6667L35.7712 33.5622C33.6884 35.645 30.3116 35.645 28.2288 33.5622L9.33333 14.6667L8.78105 14.1144C8.29848 14.597 8 15.2637 8 16.0001V48.0001C8 49.4728 9.19391 50.6667 10.6667 50.6667H53.3333C54.8061 50.6667 56 49.4728 56 48.0001Z" fill="#4D1B7E"/>
<path d="M8.78105 14.1144C9.26362 13.6318 9.93029 13.3333 10.6667 13.3333H53.3333C54.0697 13.3333 54.7364 13.6318 55.219 14.1144M8.78105 14.1144C8.29848 14.5969 8 15.2636 8 16V48C8 49.4727 9.19391 50.6666 10.6667 50.6666H53.3333C54.8061 50.6666 56 49.4727 56 48V16C56 15.2636 55.7015 14.5969 55.219 14.1144M8.78105 14.1144L28.2288 33.5621C30.3116 35.6449 33.6884 35.6449 35.7712 33.5621L55.219 14.1144" stroke="#4D1B7E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`,
steps:1
            },
            {
                heading:'Build customer loy...',
                description:'Build personalized relationship with your customers with these flows tailored for each recipient',
                icon:`<svg width="63" height="63" viewBox="0 0 63 63" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.15" d="M11.3699 31.6244L31.5 52.5L51.6301 31.6244C53.8678 29.3037 55.125 26.1563 55.125 22.8744C55.125 16.0402 49.7827 10.5 43.1926 10.5C40.0279 10.5 36.9928 11.8037 34.7551 14.1244L31.5 17.5L28.2449 14.1244C26.0072 11.8037 22.9721 10.5 19.8074 10.5C13.2173 10.5 7.875 16.0402 7.875 22.8744C7.875 26.1563 9.13216 29.3037 11.3699 31.6244Z" fill="#4D1B7E"/>
<path d="M11.3699 31.6244L31.5 52.5L51.6301 31.6244C53.8678 29.3037 55.125 26.1563 55.125 22.8744C55.125 16.0402 49.7827 10.5 43.1926 10.5C40.0279 10.5 36.9928 11.8037 34.7551 14.1244L31.5 17.5L28.2449 14.1244C26.0072 11.8037 22.9721 10.5 19.8074 10.5C13.2173 10.5 7.875 16.0402 7.875 22.8744C7.875 26.1563 9.13216 29.3037 11.3699 31.6244Z" stroke="#4D1B7E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`,
steps:1
            },
            {
                heading:'Remind people to p...',
                description:'Send personalized reminders to customers prompting them to return and purchase specific products',
                icon:`<svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.15" d="M32 8C23.1634 8 16 15.1634 16 24C16 34.6667 10.6666 45.3333 10.6666 45.3333H53.3333C53.3333 45.3333 48 34.6667 48 24C48 15.1634 40.8365 8 32 8Z" fill="#4D1B7E"/>
<path d="M24 45.3333V48C24 49.0506 24.2069 50.0909 24.6089 51.0615C25.011 52.0321 25.6002 52.914 26.3431 53.6569C27.086 54.3997 27.9679 54.989 28.9385 55.391C29.9091 55.7931 30.9494 56 32 56C33.0505 56 34.0908 55.7931 35.0614 55.391C36.032 54.989 36.9139 54.3997 37.6568 53.6569C38.3997 52.914 38.989 52.0321 39.391 51.0615C39.793 50.0909 40 49.0506 40 48V45.3333M48 24C48 32 53.3333 45.3333 53.3333 45.3333H10.6666C10.6666 45.3333 16 34.6666 16 24C16 15.2853 23.2853 8 32 8C40.7146 8 48 15.2853 48 24Z" stroke="#4D1B7E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`,
steps:1
            }],
        flowTemplates:[],
        }
    },
    mounted(){
        this.getFlowTemplates()
    },
    methods:{
       async storeFlow(id,index){
            this.isSelected=index;
           await this.store(id);
            this.isSelected=(index)
        },
        async getFlowTemplates(){
            this.isLoadingFirstTime=true;
            try{
                
            this.flowTemplates = await axios.get('api/v1/flow/templates') ;
            this.flowTemplates = this.flowTemplates.data.data
            }
            catch(error){
                console.log(error)
                throw error
            }
            this.isLoadingFirstTime=false;
        }
    }
}
</script>
<style scoped>
.quizell-card-heading{
    font-family: Poppins;
font-size: 18px;
font-weight: 500;
line-height: 27px;
letter-spacing: 0.04em;
text-align: left;

}
.quizell-card-description{
    font-family: Poppins;
font-size: 14px;
font-weight: 400;
line-height: 21px;
letter-spacing: 0.04em;
text-align: left;
color:rgba(115, 115, 141, 1);
}
.icon{
height: 156px;
padding: 46px 0px 46px 0px;
gap: 0px;
border-radius: 8px;
/* opacity: 0px; */
display:flex;
justify-content: center;
align-items: center;
background: rgba(179, 175, 182, 0.2);
}
.heading-button{
 
padding: 6px 12px 6px 12px;
border: 1px solid rgba(31, 31, 40, 0.08);
border: 1px;
font-family: Poppins;
font-size: 14px;
font-weight: 400;
line-height: 14px;
background: rgba(45, 41, 48, 0.08);
}
.goal-heading{
    font-family: Poppins;
font-size: 14px;
font-weight: 500;
line-height: 14px;
letter-spacing: 0.04em;
text-align: left;
color: rgba(31, 31, 40, 1);

}
.goal-description{
    font-family: Poppins;
font-size: 12px;
font-weight: 400;
line-height: 16.8px;
letter-spacing: 0.04em;
text-align: left;
color:rgba(115, 115, 141, 1);

}
.card:hover {
    outline: 1px solid #4D1B7E!important;
    cursor: pointer;
}
</style>